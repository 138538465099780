<template>
  <content-wrapper>
    <object-summary />
    <div class="banner">
      <img class="banner" src="@/assets/img/banners/banner-2.jpg" />
    </div>
    <near-events title="Скоро" />
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/Wrappers/ContentWrapper";
import ObjectSummary from "@/components/Event";
import NearEvents from "@/components/NearEvents";

export default {
  components: {
    ContentWrapper,
    ObjectSummary,
    NearEvents
  }
};
</script>

<style lang="scss" scoped>
.banner {
  padding: 0 32px;
  margin-top: 72px;
  border-radius: 12px;

  img {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
</style>
