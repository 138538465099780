<template>
  <div
    v-if="selectedEvent"
    class="object-full"
  >
    <swiper
      class="photos"
      :options="{
        slidesPerView: 'auto',
        touchRatio: 2
      }">
      <swiper-slide
        v-for="(img, index) in selectedEvent.images"
        :key="index">
        <img :src="img" />
      </swiper-slide>
    </swiper>

    <div class="content">
      <div class="left-side">
        <div class="title">
          {{ selectedEvent.title }}
        </div>
        <div class="imap-info__item">
          <object-item-add
            :value="date"
            icon="date"
          />
          <object-item-add
            icon="web"
            :value="webUrl"
          />
        </div>
        <div
          v-if="address"
          class="imap-info__item"
        >
          <object-item-add
            :value="address"
            icon="place"
          />
        </div>
        <object-item-add
          icon="ticket"
          :value="ticketCost"
        />
        <!-- <button
          type="submit"
          class="buy-ticket"
          @click="buyTicket"
        >
          Купить билет
        </button> -->
      </div>

      <scrolled-content class="right-side">
        <tag
          v-if="selectedEvent.type.name"
          :title="selectedEvent.type.name"
        />
        <div
          v-html="selectedEvent.description"
          class="text">
        </div>
        <!-- <txt class="text" :content="selectedEvent.description" /> -->
      </scrolled-content>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import ObjectItemAdds from "@/components/Parts/ObjectItemAdds";
import ObjectItemAdd from "@/components/Parts/ObjectItemAdd";
import ScrolledContent from "@/components/Wrappers/ScrolledContent";
import Tag from "@/components/Parts/TagSmall";

export default {
  components: {
    ObjectItemAdds,
    ObjectItemAdd,
    ScrolledContent,
    Tag
  },

  computed: {
    ...mapGetters(["selectedEvent", "GET_EVENTS"]),
    date() {
      let dateString,
        start = new Date(this.selectedEvent.started_at * 1000) || new Date(this.selectedEvent.created_at * 1000),
        stop = new Date(this.selectedEvent.stopped_at * 1000);
      if (
        this.$moment(start).format("YYYY-MM-DD") ===
        this.$moment(stop).format("YYYY-MM-DD")
      ) {
        dateString = this.$moment(start).format("DD.MM.YYYY") + " ";
        if (
          this.$moment(start).format("LT") !== this.$moment(stop).format("LT")
        ) {
          dateString +=
            this.$moment(start).format("LT") +
            " — " +
            this.$moment(stop).format("LT");
        }
        else {
          dateString += this.$moment(start).format("LT");
        }
      } else {
        if (
          this.$moment(start).format("MM-DD") ===
          this.$moment(stop).format("MM-DD")
        ) {
          dateString =
            this.$moment(start).format("DD MMMM LT") +
            " — " +
            this.$moment(stop).format("LT");
        } else if (
          this.$moment(start).format("LT") === this.$moment(stop).format("LT")
        ) {
          dateString = this.$moment(start).format("DD.MM.YYYY") + " — " +  this.$moment(stop).format("DD.MM.YYYY")
        } else {
          dateString =
            this.$moment(start).format("D MMMM LT") +
            " — " +
            this.$moment(stop).format("D MMMM LT");
        }
      }

      return dateString;
    },
    address() {
      let address;
      if (this.selectedEvent.place) {
        if (this.selectedEvent.place.title)
          address = this.selectedEvent.place.title;
        if (this.selectedEvent.place.address)
          address += ", " + this.selectedEvent.place.address;
      }
      return address || this.selectedEvent.city.title;
    },
    webUrl() {
      return this.selectedEvent.additions[0]?.value
    },
    ticketCost() {
      return this.selectedEvent.cost
    }
  },
  watch: {
    $route: "fetchData"
  },
  mounted() {
    this.fetchData();
    console.log(this.selectedEvent)
  },
  methods: {
    buyTicket() {
      console.log('BuyTicket');
    },

    fetchData() {
      if (!this.selectedEvent || this.selectedEvent.id != this.$route.params.id)
        api.getEvent(this.$route.params.id).then(res => {
          this.$store.dispatch("setSelectedEvent", res.data);
          console.log(res.data);
        });
      if (!this.GET_EVENTS) {
        this.$store.dispatch("fetchEvents");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";


.object-full {
  padding-bottom: 100px;
  margin: 0 32px;
  border-bottom: 4px solid #3d3d3c;

  .photos {
    margin-top: 56px;

    .swiper-slide {
      width: 884px;
      height: 442px;

      margin-left: 32px;
      margin-right: 12px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &:last-child {
        margin-right: 32px;
      }
    }
  }

  .content {
    margin-top: 32px;
    display: flex;
    text-align: left;

    .left-side {
      width: 488px;
      // margin-left: 32px;
      margin-right: 40px;

      .title {
        width: 488px;
        font-size: 50px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.12;
        letter-spacing: normal;
        color: rgba(#fff, 0.97);
        padding-bottom: 40px;
        border-bottom: 4px solid $color_grey_3;
        margin-bottom: 32px;
      }
    }

    .right-side {
      width: 508px;
      // width: auto;
      max-height: 646px;

      .text {
        margin-top: 32px;
        width: 488px;

        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.1px;
        color: rgba(#fff, 0.8);
        position: relative;
        padding-bottom: 32px;
      }
    }
  }

  .adds {
    padding-bottom: 10px;
    border-bottom: 4px solid #3d3d3b;
  }

  .buy-ticket {
    width: 100%;
    height: 100px;
    margin-top: 32px;
    font-size: 32px;
    font-weight: normal;
    background: linear-gradient(180deg, #62AFFF 0%, #0B83FF 105.9%);
    border-radius: 150px;
  }
}
</style>
